<template>
  <div class="col-12 my-4 text-center">
    <h1>Welcome to BHealthyForLife!</h1>
  </div>
  <div
    class="
      col-11 col-md-6 col-lg-4
      mb-2
      mx-auto
      border border-secondary border-1
      shadow
      d-flex
      flex-column
      justify-content-center
      align-content-center
      flex-wrap
    "
    style="height: 400px"
  >
    <div v-if="login_err" class="alert alert-danger w-75 text-center">
      <h4 class="alert-heading">{{ login_err }}</h4>
      <p>{{ err_desc }}</p>
      <hr />
      <p>
        <small class="text-muted"
          >Please contact your Therapist or the System Administrator</small
        >
      </p>
    </div>
    <div class="mb-4 text-center align-middle">
      <button class="btn btn-lg btn-warning" @click="extn_login">Login</button>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import authLib from '../../services/lib/auth0';
import { useRouter } from 'vue-router';
import stringLib from '../../services/lib/stringLib';

const login_err = ref(null);
const err_desc = ref(null);

export default {
  setup() {
    onBeforeMount(async () => {
      const query = window.location.search;
      if (query.includes('code=') && query.includes('state=')) {
        const router = useRouter();
        router.replace({ name: 'verify-extn' });
      } else if (query.includes('error=')) {
        const params = new URLSearchParams(query);
        login_err.value = stringLib.capitalize(params.get('error'));
        err_desc.value = params.get('error_description');
        console.log('desc = ', err_desc.value);
      }
    });

    const extn_login = async () => {
      await authLib.doLogin();
    };

    return {
      login_err,
      err_desc,
      extn_login,
    };
  },
};
</script>
