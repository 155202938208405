<template>
  <auth-header></auth-header>
  <main class="my-auto">
    <div class="container-fluid">
      <div class="row">
        <router-view></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import AuthHeader from './shared/AuthHeader.vue';
export default {
  components: {
    AuthHeader,
  },
  setup() {},
};
</script>
