const stringLib = {
  capitalize(string) {
    const words = string.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    const retval = words.join(" ");
    return retval;
  }
};

export default stringLib;
