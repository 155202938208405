<template>
  <div class="col-12 my-4 text-center">
    <h1>Welcome to BHealthyForLife!</h1>
  </div>
  <div
    class="
      col-11 col-md-6 col-lg-4
      mb-2
      mx-auto
      border border-secondary border-1
      shadow
    "
  >
    <login-ctrl></login-ctrl>
  </div>
</template>

<script>
import LoginCtrl from './components/LoginCtrl.vue';

export default {
  components: {
    LoginCtrl,
  },
  setup() {
    return {};
  },
};
</script>
