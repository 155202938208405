<template>
  <form
    ref="form-element"
    novalidate
    class="px-4 pt-5 position-relative"
    @submit.prevent="login"
  >
    <div class="form-floating mb-3">
      <input
        id="username"
        v-model.trim="username"
        name="username"
        type="text"
        :class="usernameClasses"
        placeholder="User Name"
        required
        @input="removeValidationFeedback"
      />
      <label for="username" :class="{}">User Name</label>
      <div class="invalid-feedback">Please enter your user name.</div>
    </div>
    <password-ctrl
      v-model="password"
      label="Password"
      name="password"
      :was-validated="wasValidated"
      @changes-made="removeValidationFeedback"
    >
      <template #invalid-feedback-message>
        Please enter your password.
      </template>
    </password-ctrl>
    <div
      v-if="loginFailed || accountLocked"
      class="
        alert alert-danger
        border border-1 border-danger
        d-flex
        align-items-center
        justify-content-center
      "
      role="alert"
    >
      <div>
        <i class="fas fa-exclamation-triangle fa-2x me-3"></i>
      </div>
      <div v-if="loginFailed">
        Incorrect username or password. Please try again.
      </div>
      <div v-if="accountLocked">
        Your account has been locked due to repeated unsuccessful login
        attempts. Please
        <router-link :to="{ name: 'forgot-password' }"
          >reset your password</router-link
        >.
      </div>
    </div>
    <div class="text-end mb-3">
      <button id="login-btn" type="submit" class="btn px-5 btn-secondary">
        Login
        <i class="fas fa-long-arrow-alt-right"></i>
      </button>
    </div>
  </form>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PasswordCtrl from './PasswordCtrl.vue';
export default {
  components: {
    PasswordCtrl,
  },
  setup() {
    const api = inject('api');
    const store = useStore();
    const router = useRouter();
    const loading = inject('$loading');

    const username = ref('');
    const password = ref('');

    // login
    const formElement = ref(null);
    const loginFailed = ref(false);
    const accountLocked = ref(false);
    const login = async () => {
      loginFailed.value = false;
      accountLocked.value = false;
      if (!formElement.value.checkValidity()) {
        formElement.value.classList.add('was-validated');
        wasValidated.value = true;
        return;
      }
      const loader = loading.show({
        container: formElement.value,
        isFullPage: false,
      });
      try {
        const response = await api
          .authApi()
          .login(username.value, password.value);
        store.dispatch('login', {
          username: username.value,
          userId: response.data.userId,
          token: response.data.token,
          userType: response.data.usertype,
        });
        store.dispatch('updateFullname', response.data);
        api.setAuth(response.data.token);
        loader.hide();
        if (response.data.usertype === 'professional')  {
          router.replace({name: 'pro-home'})
        } else if (response.data.usertype === 'patient') {
          router.replace({name: 'patient-home'})
        } else if (response.data.usertype === 'administrator') {
          router.replace({name: 'admin-home'})
        } else {
          router.replace({ name: 'home' });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status == 401) {
            loginFailed.value = true;
            console.log('401 unauthorized');
          } else if (error.response.status == 405) {
            accountLocked.value = true;
            console.log('405 account locked');
          }
        }
        loader.hide();
      }
    };

    // remove validation feedback once user has seen it and started typing something in
    const removeValidationFeedback = () => {
      formElement.value.classList.remove('was-validated');
      wasValidated.value = false;
    };

    // dynamic username input classes
    const usernameClasses = computed(() => {
      return {
        'form-control': true,
      };
    });

    const wasValidated = ref(false);

    return {
      password,
      username,
      'form-element': formElement,
      loginFailed,
      accountLocked,
      login,
      removeValidationFeedback,
      usernameClasses,
      wasValidated,
    };
  },
};
</script>
