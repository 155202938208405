<template>
  <div class="col-12 my-4 text-center">
    <h1 v-if="!errorValue">Verifying credentials ...</h1>
  </div>
  <div ref="loader-container" class="col-11 col-md-6 col-lg-4 mb-2 mx-auto" style="height: 200px"></div>
</template>

<script>
import { onMounted, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import authLib from '../../services/lib/auth0';

export default {
  setup() {
    console.log('at verifyextn');
    //const loading = inject('$loading');
    const api = inject('api');
    const store = useStore();
    const router = useRouter();
    const loaderContainer = ref(null);
    const errorValue = ref(false);

    onMounted(async () => {
      /*
      const loader = loading.show({
        container: loaderContainer.value,
        isFullPage: false,
      });
      */

      try {

        const login = await authLib.chkLogin(api, store);
        if (login.isFirstLogin) {
          router.replace({ name: 'reset-password' });
        } else {
          if (login.usertype === 'patient') {
            router.replace({ name: 'patient-home' })
          } else if (login.usertype === 'professional') {
            router.replace({ name: 'pro-home' })
          } else if (login.usertype === 'administrator') {
            router.replace({ name: 'admin-home' })
          } else {
            router.replace({ name: 'patient' });
          }
        }

      } catch (error) {
        console.log(error)
        errorValue.value = error.message
      }
      //loader.hide();
      //console.log(window.location);
      //window.location = window.location.origin;
    });

    return {
      'loader-container': loaderContainer,
      errorValue
    };
  },
};
</script>
